<template>
  <v-card class="accounting-document">
    <v-toolbar color="grey darken-3" dark elevation="1" height="52">
      <v-toolbar-title class="document-toolbar-title">
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-3 pb-1">
        <div class="conducted-box d-flex">
            <v-alert
                    dense
                    text
                    class="mb-0 py-1"
                    :color="conducted ? 'success' : 'secondary'"
                    :type="conducted ? 'success' : 'warning'"
                    style="flex: 1"
            >
                {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
            </v-alert>
        </div>
      <div class="document-header">
        <div class="document-header-row px-1 pt-2 pb-1">
          <div style="flex: 0 0 180px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true" required/>
          </div>
          <div style="flex: 0 0 144px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"/>
          </div>
          <div style="flex: 0 0 310px; margin-right: 16px">
            <string_input v-model="person_name" label="Працівник: " :disabled="true"/>
          </div>
        </div>
      </div>
      <div class="document-body pt-0">
        <div class="block-divider">
          Табличні дані
        </div>
        <div class="px-2 pt-1 pb-1">
          <v-tabs class="document-small-tabs" color="success">
            <v-tab class="text-left justify-start">
              Трудовий договір
            </v-tab>
            <v-tab class="text-left justify-start">
              Нарахування
            </v-tab>

            <v-tab-item class="pa-0 px-1 pb-4">
              <div class="document-body">
                <div class="document-body-row pt-3">
                  <div style="flex: 0 0 340px; margin-right: 16px">
                    <select_input
                        v-model="subdivision_id"
                        :computed-action="'SELECT_SUBDIVISION_LIST_ELEMENTS'"
                        :computed-getter="'get_subdivision_list_select'"
                        :input-value-as-value="false"
                        :item-search="'text'"
                        :show-on-focus="false"
                        :row_num="0"
                        label="Підрозділ"
                        :show-select-on-focus="false"
                        :required="true"
                        :select-button-object="{
                              name: 'SUBDIVISION_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'Список підрозділ',
                              element_title: 'Підрозділ',
                              show: true
                            }"
                    />
                  </div>
                  <div style="flex: 0 0 310px; margin-right: 16px">
                    <select_input
                        v-model="position_id"
                        :computed-action="'SELECT_POSITION_LIST_ELEMENTS'"
                        :computed-getter="'get_position_list_select'"
                        :input-value-as-value="false"
                        :item-search="'text'"
                        :show-on-focus="false"
                        :row_num="0"
                        label="Посада"
                        :show-select-on-focus="false"
                        :required="true"
                        :select-button-object="{
                              name: 'POSITION_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'Список посад',
                              element_title: 'Посада',
                              show: true
                            }"
                    />
                  </div>
                </div>
                <div class="document-body-row pt-3">
                  <div style="flex: 0 0 340px; margin-right: 16px">
                    <number_input label="К-сть ставок" v-decimal v-model="rate_count" required/>
                  </div>
                  <div style="flex: 0 0 310px; margin-right: 16px">
                    <date_input label="Дата прийняття" v-model="hire_date" required/>
                  </div>
                </div>
                <div class="document-body-row pt-3">
                  <div style="flex: 0 0 340px; margin-right: 16px">
                    <select_input
                        v-model="work_schedule_id"
                        :computed-action="'SELECT_WORK_SCHEDULE_LIST_ELEMENTS'"
                        :computed-getter="'get_work_schedule_list_select'"
                        :input-value-as-value="false"
                        :item-search="'text'"
                        :show-on-focus="false"
                        :row_num="0"
                        label="Графік роботи"
                        :show-select-on-focus="false"
                        :required="true"
                        :select-button-object="{
                              name: 'WORK_SCHEDULE_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'Список графіків роботи',
                              element_title: 'Графік роботи',
                              show: true
                            }"
                    />
                  </div>
                  <div style="flex: 0 0 310px; margin-right: 16px">
                    <select_input
                        v-model="chart_of_account"
                        :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                        :computed-getter="'get_chart_of_accounts_list_select'"
                        :input-value-as-value="false"
                        :item-search="'text'"
                        :show-on-focus="false"
                        :row_num="0"
                        label="Рахунок витрат"
                        :show-select-on-focus="false"
                        :required="true"
                        :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'Список рахунків бух.обліку',
                              element_title: 'Рахунок бух.обліку',
                              show: true
                            }"
                    />
                  </div>
                </div>
                <div class="document-body-row pt-3">
                  <div style="flex: 0 0 340px; margin-right: 16px">
                    <v-checkbox hide-details color="grey" label="Це нове робоче місце" class="" v-model="new_work_place"/>
                  </div>
                  <div style="flex: 0 0 310px; margin-right: 16px">
                    <date_input label="Дата ств.нов.роб. місця" v-model="new_work_place_date" :required="false"/>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item class="pa-0 px-1 pb-4">
              <div class="pt-3 pa-2">
                <v-btn small depressed color="success" class="mr-2" @click.stop="addAccrual">
                  Додати
                </v-btn>
                <v-btn small depressed color="error lighten-1" class="mr-2"
                       :disabled="!accrual_selected"
                       @click="removeAccrual"
                >
                  Вилучити
                </v-btn>
              </div>
              <div class="document-body-row pa-2"
                   v-for="(accrual, idx) in accruals"
                   :key="`accrual-${accrual.row_idx}`"
              >
                <div style="flex: 0 0 30px; align-self: center; margin-top: 6px">
                  {{ idx + 1 }}
                </div>
                <div style="flex: 0 0 50px; align-self: center">
                  <v-checkbox v-model="accrual.selected" hide-details color="success" class="mt-0"/>
                </div>

                <div style="flex: 0 0 318px; margin-right: 16px">
                  <select_input
                      v-model="accrual.accrual_id"
                      :computed-action="'SELECT_ACCRUAL_LIST_ELEMENTS'"
                      :computed-getter="'get_accrual_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="accrual.row_idx"
                      label="Вид нарахування"
                      :show-select-on-focus="false"
                      :select-button-object="{
                            name: 'ACCRUAL_ELEMENTS',
                            group: '',
                            hierarchy: false,
                            only_groups: false,
                            title: 'Список видів нарахувань',
                            element_title: 'Вид нарахування',
                            show: true
                          }"
                      @selectChange="onAccrualIdChange"
                  />
                </div>

                <div style="flex: 0 0 140px; margin-right: 16px">
                  <number_input label="Розмір" v-model="accrual.sum" v-decimal
                                :required="true"
                  />
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <template>
        <v-spacer></v-spacer>
        <v-btn depressed color="grey lighten-3" v-if="!conducted" :loading="getModalLoading" :disabled="isNew"
               @click="doConducted(true)">
          <v-icon color="success">mdi-file-check-outline</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="conducted" :loading="getModalLoading" :disabled="isNew"
               @click="doConducted(false)">
          <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
        </v-btn>
        <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
               class="button-accept">
          <v-icon left>mdi-content-save</v-icon>
          Провести та закрити
        </v-btn>
        <v-btn depressed text tile color="grey" @click="closeModal">
          <v-icon left>mdi-close</v-icon>
          Відмінити
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  UPDATE_DOCUMENT_EMPLOYMENT_ELEMENTS,
  REMOVE_DOCUMENT_EMPLOYMENT_ELEMENTS,
  CONDUCTED_DOCUMENT_EMPLOYMENT_ELEMENTS, SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import employmentAPI from "@/utils/axios/accounting/documents/employment"

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";
import {getDateFromString} from "@/utils/icons";

const modalDeleteId = 'employment_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Employment.vue",
  mixins: [ModalAccountingComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    date_input: () => import("@/components/accounting/input/document_date_input")
  },
  data() {
    return {
      accruals: [],
      selected_row: -1,
      person_id: this.item.person_id || null,
      person_name: this.item.person_name,
      rate_count: this.item.rate_count || 0,
      new_work_place: this.item.new_work_place || false,
      new_work_place_date: this.item.new_work_place_date || null,
      work_schedule_id: this.item.work_schedule_id || null,
      hire_date: this.item.hire_date || null,
      subdivision_id: this.item.subdivision_id || null,
      position_id: this.item.position_id || null,
      chart_of_account: this.item.chart_of_account || null,
    }
  },
  methods: {
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0;
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show = false, table = true) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'person_id': {id: 'person_id', text: 'Не заповнений працівник'},
        'work_schedule_id': {id: 'work_schedule_id', text: 'Не заповнений графік роботи'},
        'hire_date': {id: 'date', text: 'Не заповнена дата прийняття'},
        'rate_count': {id: 'rate_count', text: 'Не заповнена кількість ставок'},
        'subdivision_id': {id: 'subdivision_id', text: 'Не заповнений підрозділ'},
        'position_id': {id: 'position_id', text: 'Не заповнена посада'},
        'chart_of_account': {id: 'chart_of_account', text: 'Не заповнений рахунок бух.обліку'},
      }

      const table_accruals = {
        'accrual_id': {id: 'accrual_id', table: 'Нарахування', text: 'Не заповнений вид нарахування'},
        'sum': {id: 'sum', table: 'Нарахування', text: 'Не заповнена сума'},
      }


      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      if (this.date && this.hire_date) {
        if (getDateFromString(this.date) < getDateFromString(this.hire_date)) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: 'Дата документа має бути більшою за дату прийняття на роботу'}
          )
        }
      }

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(table_accruals, 'Нарахування', 'accruals')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_EMPLOYMENT_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, { text, color })
            }

          })
    },
    addMaxAccrualRow() {
      return Math.max(...this.accruals.map(i => i.row_idx) || [0, 0])
    },
    addAccrual() {
      this.accruals.push({
        accrual_id: null,
        status: 'start',
        sum: 0,
        row_idx: this.addMaxAccrualRow() + 1,
        selected: false
      })
    },
    removeAccrual() {
      this.accruals.filter(i => i.selected).forEach(row => {
        this.accruals.splice(this.accruals.indexOf(row), 1)
      })
    },
    onAccrualIdChange(payload) {
      const value = (payload || {}).value || (payload || {}).id || null
      const row_idx = (payload || {}).row_num || -1

      if (this.accruals.filter(i => i.row_idx !== row_idx && i.accrual_id === value).length) {
        const item = this.accruals.find(i => i.row_idx === row_idx)

        this.$nextTick(() => {
          item.accrual_id = null
        })
      }
    },

    clearPersonData(payload) {
      this.date = payload.date || null
      this.number = payload.number || null
      this.person_id = payload.person_id || null
      this.person_name = payload.person_name || ""
      this.rate_count = payload.rate_count || 0
      this.new_work_place = payload.new_work_place || false
      this.new_work_place_date = payload.new_work_place_date || null
      this.work_schedule_id = payload.work_schedule_id || null
      this.hire_date = payload.hire_date || null
      this.subdivision_id = payload.subdivision_id || null
      this.position_id = payload.position_id || null
      this.chart_of_account = payload.chart_of_account || null
      this.itemId = payload.id || null
      this.conducted = payload.conducted
      this.accruals = []
      this.selected_row = -1
    },
    fetch_data_by_id() {
      if (this.isNew) return

      employmentAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.conducted = data.conducted || false
            this.date = data.date || null
            this.number = data.number || null
            this.person_id = data.person_id || null
            this.person_name = data.person_name || null
            this.rate_count = data.rate_count || 0
            this.new_work_place = data.new_work_place || null
            this.new_work_place_date = data.new_work_place_date || null
            this.work_schedule_id = data.work_schedule_id || null
            this.hire_date = data.hire_date || null
            this.subdivision_id = data.subdivision_id || null
            this.position_id = data.position_id || null
            this.chart_of_account = data.chart_of_account || null
            this.selected_row = -1

            this.accruals = data.accruals
          })
    },
    load_select_data() {
      return Promise.all([
        this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
      ])
    },

    validateForm(only_bool = false) {
      const required_items = {
        'date': 'Дата',
        'person_id': 'Працівник',
        'rate_count': 'К-сть ставок',
        'work_schedule_id': 'Графік роботи',
        'hire_date': 'Дата прийняття',
        'subdivision_id': 'Підрозділ',
        'position_id': 'Посада',
        'chart_of_account': 'Рахунок бух.обліку',
      }

      let form_valid = true
      let errors = []

      Object.keys(required_items).forEach(key => {
        if (!this[key]) {
          form_valid = false
          if (!only_bool) {
            errors.push(required_items[key])
          }
        }
      })

      if (only_bool) return form_valid
      let text = 'Виправте помилки: \n' + errors.join(', ') + ' - мають бути заповнені. \n'
      return {
        valid: form_valid,
        text: text
      }

    },
    chartOfAccountChange(payload) {
      this.chart_of_account = (payload || {})?.value || null
    },
    subdivisionChange(payload) {
      this.subdivision_id = (payload || {})?.value || null
    },
    positionChange(payload) {
      this.position_id = (payload || {})?.value || null
    },
    workScheduleChange(payload) {
      this.work_schedule_id = (payload || {})?.value || null
    },

    closeModal() {
      this.$emit('closeModal')
      this.clearPersonData(this.item)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення прийому на роботу № ${this.number} (${this.person_name})`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        id: this.itemId,
        date: this.date,
        number: this.number,
        person_id: this.person_id,
        subdivision_id: this.subdivision_id,
        position_id: this.position_id,
        rate_count: this.rate_count,
        hire_date: this.hire_date,
        work_schedule_id: this.work_schedule_id,
        new_work_place: this.new_work_place || false,
        new_work_place_date: this.new_work_place_date,
        chart_of_account: this.chart_of_account,
        accruals: this.accruals.map(item => {
          return {accrual_id: item.accrual_id, sum: item.sum}
        })
      }

      this.$store.dispatch(UPDATE_DOCUMENT_EMPLOYMENT_ELEMENTS, payload)
          .then(el => {
            if (el) {
              this.closeModal()
            }
          })
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.load_select_data()
              this.checkDocument()
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_EMPLOYMENT_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    }
  },
  computed: {
    accrual_selected() {
      return !!this.accruals.filter(i => i.selected).length
    }
  }
}
</script>
